<template>
    <div class="index" style="padding: 20px">
        <Row :gutter="16" style="margin-bottom:10px;">
            <Col span="22" offset="1">
            <Input v-model="callbackUrl">
            <span slot="prepend">回调地址</span>
            </Input>
            </Col>
        </Row>
        <Row :gutter="16" style="margin-bottom:10px;">
            <Col span="22" offset="1">
            <Input v-model="agentId">
            <span slot="prepend">企业ID</span>
            </Input>
            </Col>
        </Row>
        <Row :gutter="16" style="margin-bottom:10px;">
            <Col span="22" offset="1">
            <Input v-model="appId">
            <span slot="prepend">AppId</span>
            </Input>
            </Col>
        </Row>
        <Row :gutter="16" style="margin-bottom:10px;">
            <Col span="22" offset="1">
            <Input v-model="appSecurity">
            <span slot="prepend">AppSecurity</span>
            </Input>
            </Col>
        </Row>
        <Row :gutter="16" style="margin-bottom:10px;">
            <Col span="22" offset="1">
            <Input v-model="enterpriseSenderCode">
            <span slot="prepend">企业发送码</span>
            </Input>
            </Col>
        </Row>
        <div>
            <Row :gutter="16" style="margin-bottom:10px;">
                <Col span="20" offset="1">
                <Input :disabled="true" v-model="publicKey">
                <span slot="prepend">公钥</span>
                </Input>
                </Col>
                <Col>
                <Button style="margin-left:10px;" type="primary" @click="handleClipboard($event,publicKey)">
                    复制
                </Button>
                </Col>
            </Row>
            <Row :gutter="16" style="margin-bottom:10px;">
                <Col span="20" offset="1">
                <Input :disabled="true" v-model="privateKey">
                <span slot="prepend">私钥</span>
                </Input>
                </Col>
                <Col>
                <Button style="margin-left:10px;" type="primary" @click="handleClipboard($event,privateKey)">
                    复制
                </Button>
                </Col>
            </Row>
        </div>
        <div style="padding:20px;text-align: center;">
            <Button type="primary" @click="generateKey()" style="margin-right:20px">更新公私钥</Button>
            <Button type="primary" @click="handleSubmit()">保存</Button>
        </div>
    </div>
</template>
<script>
import { ajax } from '../../libs/util';
import Clipboard from 'clipboard'

export default {
    data() {
        return {
            callbackUrl: '',
            agentId: '',
            publicKey: '',
            privateKey: '',
            appId: '',
            appSecurity: '',
            enterpriseSenderCode: ''
        }
    },
    methods: {
        handleSubmit() {
            let options = {
                callbackUrl: this.callbackUrl,
                agentId: this.agentId,
                publicKey: this.publicKey,
                privateKey: this.privateKey,
                appId: this.appId,
                appSecurity: this.appSecurity,
                enterpriseSenderCode: this.enterpriseSenderCode
            }
            ajax.Post('/user/set_config', options).then(res => {
                this.$Message.success("修改成功");
            })
        },
        init() {
            ajax.Post('/user/get_user_info', {}).then(res => {
                this.callbackUrl = res.callbackUrl
                this.agentId = res.agentId
                this.publicKey = res.publicKey
                this.privateKey = res.privateKey
                this.appId = res.appId
                this.appSecurity = res.appSecurity
                this.enterpriseSenderCode = res.enterpriseSenderCode
            })
        },
        generateKey() {
            ajax.Post('/user/create_key', {}).then(res => {

                this.publicKey = res.publicKey
                this.privateKey = res.privateKey
                this.$Message.success("已生成新的公私钥，请点击保存！");
            })
        },
        handleClipboard(event, text) {
            const clipboard = new Clipboard(event.target, {
                text: () => text
            })
            clipboard.on('success', () => {
                this.$Message.success("复制成功");
                clipboard.destroy()
            })
            clipboard.on('error', () => {
                this.$Message.error("复制失败");
                clipboard.destroy()
            });
            clipboard.onClick(event)
        }
    },
    mounted() {
        this.init();
    }
};
</script>
<!--登录后首页-->
<style scoped lang="less">
.index {
    height: 100%;

    h1 {
        line-height: 100%;
        padding: 200px 0;
    }
}

.index .ivu-row-flex {
    height: 100%;
}
</style>